import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"
import { FaArrowRight } from "react-icons/fa"
import styled from "styled-components"
import { styles } from "../utils"
import Dish from "../components/globals/Dish"
import leftDish from "../images/gallery/azalea-carbonara.jpg"
import rightDish from "../images/gallery/azalea-beet-salad.jpg"
import DailySpecials from "../components/dailySpecials"
import { documentToHtmlString } from "@contentful/rich-text-html-renderer"
import { nowIsBetweenContentfulDates } from "../utils/dates"

const Menu = ({ coverImage, name, subtitle, pdf }) => {
  const image = getImage(coverImage)
  return (
    <MenuSection>
      <GatsbyImage
        alt={coverImage.description}
        className="menuCoverImg"
        image={image}
      />
      <article>
        <header className="headerWrapper">
          {subtitle && <span className="season">{subtitle}</span>}
          <h2 className="menuType">{name}</h2>
        </header>
        <footer>
          <a className="menuLink" href={pdf.file.url}>
            View Menu <FaArrowRight />
          </a>
        </footer>
      </article>
    </MenuSection>
  )
}

const SimpleMenu = ({ name, pdf }) => (
  <SimpleMenuArticle>
    <header className="headerWrapper">
      <h2 className="menuType">{name}</h2>
    </header>
    <footer>
      <a className="menuLink" href={pdf.file.url}>
        View Menu <FaArrowRight />
      </a>
    </footer>
  </SimpleMenuArticle>
)

const SpecialOccasionMenu = ({ occasion }) => {
  const [html, setHtml] = React.useState(null)

  React.useEffect(() => {
    const { raw } = occasion.restaurantSpecialOccasion?.description
    if (!raw) return

    const htmlString = documentToHtmlString(raw)
    setHtml(htmlString)
  }, [occasion])

  const {
    restaurantSpecialOccasion,
    pdf,
  } = occasion
  
  const _html = documentToHtmlString(
    JSON.parse(restaurantSpecialOccasion?.description?.raw || ``)
  )

  const { showFrom, showUntil } = restaurantSpecialOccasion
  if (!nowIsBetweenContentfulDates(showFrom, showUntil)) return <></>

  return (
    <>
      <SpecialOccasion>
        <header className="headerWrapper">
          <span>Join Us For</span>
          <h2>{restaurantSpecialOccasion.name}</h2>
        </header>
        <div dangerouslySetInnerHTML={{ __html: html || _html }} />
        <footer>
          <a className="menuLink" href={pdf.file.url}>
            View Menu <FaArrowRight />
          </a>
        </footer>
      </SpecialOccasion>
    </>
  )
}

const MenuPage = ({ data }) => {
  const primaryMenus = data.primaryMenus.nodes
  const secondaryMenus = data.secondaryMenus.nodes
  const specialOccasion = data.specialOccasionMenu

  const chickenSandwich = data.chickenSandwich
  const pintsOnThePatio = data.pintsOnThePatio

  const dishes = [
    {
      img: chickenSandwich,
      name: `Chicken Sandwich`,
      pos: `100% 50%`,
    },
    {
      align: `right`,
      img: pintsOnThePatio,
      name: `Pints on the Patio`,
      pos: `50% 20%`,
      posMd: `100% 40%`,
    },
  ]

  return (
    <Layout>
      <Seo
        title="Menu"
        keywords={[
          `azalea bar & kitchen menu`,
          `breakfast lunch dinner in asheville nc`,
          `restaurants near biltmore in asheville nc`,
        ]}
      />

      <div id="menu-links">
        {specialOccasion && (
          <SpecialOccasionWrapper>
            <SpecialOccasionMenu occasion={specialOccasion} />
          </SpecialOccasionWrapper>
        )}
        <Wrapper specialOccasion={specialOccasion}>
          {primaryMenus.map((menu, index) => (
            <div className="sectionWrapper primary" key={index}>
              <Menu {...menu} />
            </div>
          ))}
          <div className="sectionWrapper secondary">
            {secondaryMenus.map((menu, index) => (
              <SimpleMenu {...menu} key={index} />
            ))}
          </div>
        </Wrapper>
      </div>
      <DailySpecials />
      <Dishes>
        {dishes.map((dish, index) => (
          <Dish key={index} {...dish} />
        ))}
      </Dishes>
    </Layout>
  )
}

const SpecialOccasionWrapper = styled.div`
  background: ${styles.colors.primaryColor};
  background-size: cover;
  padding: 10.8rem 0rem 2rem;
  display: grid;
  place-content: center;

  & > * {
    grid-area: 1 / 1;
  }
`

const SpecialOccasion = styled.article`
  --inset: 1rem;
  --p: 2rem;
  --s1: 5%;
  --s2: 95%;

  background: linear-gradient(
    45deg,
    ${styles.colors.primaryColor} var(--s1),
    ${styles.colors.secondaryColor} var(--s1),
    ${styles.colors.secondaryColor} var(--s2),
    ${styles.colors.primaryColor} var(--s2)
  );
  color: ${styles.colors.mainBlack};
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  margin: auto;
  padding: var(--p);
  width: calc(100vw - var(--inset));

  & > header {
    h2 {
      color: ${styles.colors.mainBlack};
      font: ${styles.fontDecor({ size: `2.5rem`, weight: 900 })};
      letter-spacing: -1px;
    }

    span {
      color: ${styles.colors.mainBlack};
      font-size: 1rem;
      letter-spacing: 1px;
      text-transform: uppercase;
    }
  }

  & > p {
    color: ${styles.colors.mainBlack};
    font-size: 1.1rem;
    margin: 0;
  }

  & > footer {
    display: flex;
    justify-content: end;

    a {
      background: ${styles.colors.mainBlack};
      display: flex;
      flex-direction: row;
      gap: 0.5rem;
      letter-spacing: 1px;
      font-weight: 600;
      align-items: center;
      padding: 1rem 2rem;
      color: ${styles.colors.secondaryColor};
      width: fit-content;
      text-transform: uppercase;

      svg {
        transition: translate 0.2s;
      }

      &:hover {
        color: ${styles.colors.mainWhite};

        svg {
          translate: 0.25rem 0;
        }
      }
    }
  }

  @media (min-width: 576px) {
    --inset: 2rem;
    --p: 3rem 4rem;
    max-width: calc(768px - var(--inset));
  }
`

const Wrapper = styled.section`
  display: grid;
  grid-template: var(--temp);
  min-height: 60vh;

  .sectionWrapper {
    --inset-offset: 0;
    --y-pad: 12rem;
    background-color: var(--bg);
    color: var(--color);
    padding-top: var(--inset-offset);

    &.primary:first-child {
      --inset-offset: ${props => props.specialOccasion ? `0` : `10.8rem`};
    }

    &:nth-child(odd) {
      --accent: ${styles.colors.mainBlack};
      --bg: ${styles.colors.primaryColor};
      --bg2: ${styles.colors.secondaryColor};
      --color: ${styles.colors.primaryColor};
    }

    &.primary:nth-child(odd) {
      --hdr-align: end;
      --justify: end;
      --pad: 0 1rem 0 0;
      --t: -1rem;
      --txt-align: right;
    }

    &:nth-child(even) {
      --accent: ${styles.colors.mainWhite};
      --bg: ${styles.colors.secondaryColor};
      --bg2: ${styles.colors.primaryColor};
      --color: ${styles.colors.secondaryColor};
    }

    &.primary:nth-child(even) {
      --hdr-align: start;
      --justify: start;
      --pad: 0 0 0 1rem;
      --t: 1rem;
      --txt-align: left;
    }

    &.secondary {
      --align: center;
      --flex-dir: column;
      --justify: center;
      --w: 90%;
      display: flex;
      flex-direction: var(--flex-dir);
      flex-wrap: wrap;
      align-items: var(--align);
      justify-content: var(--justify);
      padding: 4rem;
      gap: 2rem;

      & > article {
        max-width: calc(768px - 1rem);
        width: var(--w);
      }
    }

    @media (min-width: 576px) {
      padding-bottom: 4rem;

      &.primary:not(:first-child) {
        padding-top: 4rem;
      }
    }

    @media (min-width: 1200px) {
      &.secondary {
        --align: end;
        --flex-dir: row;
        --justify: center;
        --w: 40%;

        article {
          max-width: calc(50vw / 2);
        }
      }
    }

    header {
      background: var(--bg2);
      display: flex;
      flex-direction: column;
      padding: 0.5rem;
      width: 100%;
    }

    h2.menuType {
      font: ${styles.fontBody({
        size: `3rem`,
        lineHeight: 0.85,
        weight: 600,
      })};
      margin-bottom: 0;
      text-align: var(--txt-align);
      text-wrap: balance;
      width: fit-content;
    }

    .menuLink {
      --tx: 0rem;
      align-items: center;
      background: var(--accent);
      color: var(--bg2);
      display: flex;
      font-weight: 600;
      gap: 0.5rem;
      letter-spacing: 1px;
      padding: 1rem 2rem;
      width: fit-content;

      svg {
        translate: var(--tx) 0;
        ${styles.transDefault};
      }

      &:hover {
        --tx: 0.25rem;
      }
    }
  }
`

const MenuSection = styled.section`
  --inset: 1rem;
  --inset-offset: 0rem;
  --y-pad: 12rem;
  --w: 100%;
  background: inherit;
  display: flex;
  flex-direction: column;
  isolation: isolate;
  align-items: var(--justify);
  justify-content: var(--justify);
  margin: auto;
  max-width: 768px;
  overflow: hidden;
  padding: var(--y-pad) 0;
  position: relative;

  &::before {
    border: 2px solid var(--bg2);
    bottom: calc(var(--inset) / 2);
    content: "";
    height: calc(100% - var(--inset) - var(--inset-offset));
    left: calc(var(--inset) / 2);
    position: absolute;
    width: calc(100% - var(--inset));
  }

  @media (min-width: 576px) {
    --inset: 2rem;
    --w: 55%;
  }

  .menuCoverImg {
    margin: auto;
    max-width: calc(100% - var(--inset));

    @media (min-width: 576px) {
      translate: var(--t);
    }
  }

  article {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    isolation: isolate;
    padding: 0 0.5rem;
    position: relative;
    row-gap: 2rem;
    text-transform: uppercase;
    min-width: fit-content;
    width: var(--w);

    header {
      align-items: var(--hdr-align);

      .season {
        color: var(--accent);
        font: ${styles.fontBody({ size: `0.9rem`, weight: 400 })};
        letter-spacing: 4px;
      }
    }

    @media (min-width: 576px) {
      margin-top: 1rem;
      padding: var(--pad);
      translate: calc(var(--t) * -1);
    }
  }
`

const SimpleMenuArticle = styled.article`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  text-transform: uppercase;
`

const Dishes = styled.section`
  display: flex;
  gap: 1rem;
  height: 60vh;
  margin: auto;
  max-width: 768px;
  padding: 1rem;

  article:nth-child(2) {
    display: none;
  }

  @media (min-width: 576px) {
    article:nth-child(2) {
      display: block;
    }
  }
`

export const query = graphql`
  query {
    primaryMenus: allContentfulMenu(
      filter: {
        restaurant: { elemMatch: { name: { eq: "Azalea Bar & Kitchen" } } }
        primaryMenu: { eq: true }
        specialOccasionMenu: { ne: true }
      },
      sort: {
        order: ASC,
        fields: displayOrder,
      }
    ) {
      nodes {
        name
        subtitle
        coverImage {
          description
          gatsbyImageData(quality: 75, formats: WEBP)
        }
        pdf {
          file {
            url
          }
        }
      }
    }
    secondaryMenus: allContentfulMenu(
      filter: {
        restaurant: { elemMatch: { name: { eq: "Azalea Bar & Kitchen" } } }
        primaryMenu: { ne: true }
        specialOccasionMenu: { ne: true }
      },
      sort: {
        order: ASC,
        fields: displayOrder,
      }
    ) {
      nodes {
        name
        pdf {
          file {
            url
          }
        }
      }
    }
    specialOccasionMenu: contentfulMenu(
      restaurant: { elemMatch: { name: { eq: "Azalea Bar & Kitchen" } } }
      specialOccasionMenu: { eq: true },
    ) {
      # coverImage {
      #   gatsbyImageData(
      #     quality: 75,
      #     formats: WEBP,
      #     placeholder: BLURRED,
      #     width: 768
      #   )
      # }
      name
      pdf {
        file {
          url
        }
      }
      restaurantSpecialOccasion {
        description {
          raw
        }
        name
        showFrom
        showUntil
      }
    }
    chickenSandwich: contentfulAsset(
      title: { eq: "ABK - Hot Chicken Sandwhich" }
    ) {
      description
      gatsbyImageData(
        formats: WEBP
        quality: 75
        width: 750
      )
    }
    pintsOnThePatio: contentfulAsset(
      title: { eq: "ABK - Pints on the Patio" }
    ) {
      description
      gatsbyImageData(
        formats: WEBP
        quality: 75
        width: 750
      )
    }
  }
`

export default MenuPage
