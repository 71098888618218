import React from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { styles } from "../../utils"

const Dish = ({ align = `left`, img, name, pos = `50% 50%`, posMd }) => {
  return (
    <ShowcasedDish alignment={align} loading="lazy">
      <header>{name}</header>
      <GatsbyImage
        alt={img.description}
        image={getImage(img)}
        style={{ "--obj-p": pos, "--obj-p-md": posMd }}
      />
    </ShowcasedDish>
  )
}

const ShowcasedDish = styled.article`
  --bg: white;

  background: var(--bg);
  height: 100%;
  isolation: isolate;
  position: relative;
  width: 100%;

  header {
    background: var(--bg);
    bottom: 0;
    color: ${styles.colors.mainBlack};
    font-weight: 700;
    padding: 0.5rem var(--gap, 0.5rem);
    position: absolute;
    text-transform: uppercase;
    z-index: 10;
    ${props => props.alignment}: 0;
  }

  .gatsby-image-wrapper {
    height: 100%;
    inset: 0;
    position: absolute;
    width: 100%;
    z-index: 0;
  }
`

export default Dish
